<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="4">
        <h2>Cash Receipt</h2>
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        md="4">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getItems(true)" />
          <v-btn
            color="secondary"
            :disabled="loading"
            to="/cash-receipt/create"
            class="ml-2 mb-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :item-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import CashReceiptProvider from '@/resources/CashReceiptProvider'

const CashReceiptService = new CashReceiptProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: 'วันที่เอกสาร',
          value: 'postingDate',
          align: 'center',
          sortable: false
        },
        {
          text: 'รหัสเอกสาร',
          value: 'documentNumber',
          align: 'center',
          sortable: false
        },
        {
          text: 'รหัสบัญชี Suspense CN',
          value: 'balAccountNumber',
          align: 'center',
          sortable: false
        },
        {
          text: 'รหัสบัญชี รายได้อื่น',
          value: 'accountNumber',
          align: 'center',
          sortable: false
        },
        {
          text: 'รายละเอียดการทำรายการ',
          value: 'description',
          align: 'center',
          sortable: false
        },
        {
          text: 'จำนวนเงิน',
          value: 'amount',
          align: 'center',
          sortable: false
        }
      ],
      items: [],
      query: {
        page: 1,
        limit: 10,
        search: ''
      },
      pageCount: 0,
      loading: false
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await CashReceiptService.getCashReceipts({
          page: this.query.page,
          limit: this.query.limit,
          search: this.query.search
        })

        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
